import {gql} from "@apollo/client";

export const GET_BRAND_CAMPAIGN_NAMES = gql`
  query getBrandCampaigns($brandId: ID!) {
    getBrandCampaigns(brandId: $brandId) {
      id
      name
    }
  }
`;

export const GET_BRAND_CAMPAIGN_USERS_DATA = gql`
  query getBrandCampaignUsersData($campaignId: ID!) {
    getBrandCampaignUsersData(campaignId: $campaignId) {
      results
    }
  }
`;