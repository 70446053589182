import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "./customHooks/useWindowDimentions";

const options = {
  labels: ["Likes", "Comments", "Engagement", "Reach", "Clicks"],
  legend: {
    fontSize: "16px",
    labels: {
      colors: undefined,
      useSeriesColors: true,
    },
  },
};

const campaigns = [
  { id: 0, name: "Kela Hair Campaign" },
  { id: 1, name: "Esseal Fashion Run" },
  { id: 2, name: "Comfort Garments New Year Sale" },
  { id: 3, name: "Charcoal Intro Campaign" },
];

const seriesArray = [
  [12000, 7689, 19689, 20456, 6578],
  [9000, 4590, 15983, 18965, 3409],
  [22000, 5894, 17029, 24001, 6553],
  [15000, 9049, 23094, 20126, 8765],
];

const CampaignCostBreakdown = (props) => {
  const { height, width } = useWindowDimensions();
  const [selectedGraph, setSelectedGraph] = useState(0);
  const [series, setSeries] = useState();
  const { userRole, chart } = props;

  const handleChange = (event) => {
    setSelectedGraph(event.target.value);
  };

  useEffect(() => {
    setSeries(seriesArray[selectedGraph]);
  }, [selectedGraph]);

  return (
    <Grid item xs container justifyContent="center">
      <Grid item xs={11} container justifyContent="space-between">
        <Typography variant="h4"> {campaigns[selectedGraph][1]}</Typography>
        <Box sx={{ minWidth: 200, marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ color: "#FFF", fontSize: "1rem !important" }}
            >
              Select Campaign
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Campaign"
              variant="standard"
              sx={{
                color: "#FFF",
                background: "#FFA3C7",
                borderRadius: 1,
                padding: 1,
              }}
              onChange={handleChange}
            >
              {campaigns.map((campaign) => (
                <MenuItem value={campaign.id}>{campaign.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Chart
        options={options}
        series={series}
        type="donut"
        height={height / 1.5}
        width={width - 80}
      />
    </Grid>
  );
};

export default CampaignCostBreakdown;
