import React, { useState } from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";

import theme from "./globalTheme";
import Navbar from "./components/Navbar";
import Login from "./pages/auth/login";
import CampaignDetail from "./components/campaignComponents/campaignDetail";
import InfluencerDetails from "./pages/influencerDetails";

const App = () => {
  const [user, isUser] = useState<any>(!!localStorage.getItem("currentUser"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        height="100vh"
        display="flex"
        justifyContent="start"
        alignItems="start"
        flexDirection="column"
      >
        <Router>
          {user && <Navbar setLoggedOut={() => isUser(false)} />}
          <Routes>
            <Route path="/admin-portal" element={<Login isUser={isUser} />} />
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={user ? <route.component /> : <Login isUser={isUser} />}
              />
            ))}
            <Route
              path="/campaign/:id"
              element={user ? <CampaignDetail /> : <Login isUser={isUser} />}
            />
            <Route
              path="/influencer/:id"
              element={user ? <InfluencerDetails /> : <Login isUser={isUser} />}
            />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default App;
