import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import GET_INFLUENCER_INSIGHTS from "../../graphql/queries/getInfluencerInsights";
import UPDATE_TIKTOK_STATS from "../../graphql/mutations/submitTiktokStat";
import UPDATE_INSTAGRAM_STATS from "../../graphql/mutations/submitInstagramStat";
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

type InsightsSectionType = {
  id: string;
};
const InsightsSection = ({ id }: InsightsSectionType) => {
  const { data } = useQuery(GET_INFLUENCER_INSIGHTS, {
    variables: { userId: id },
  });

  if (!data) return <></>;
  return (
    <Grid item xs={12} container>
      <InsightsForm type="Instagram" insights={data.getMyInstagramStats} />
      <InsightsForm type="TikTok" insights={data.getMyTiktokStats} />
    </Grid>
  );
};

export default InsightsSection;

type InsightType = {
  id: string | number;
  link: string;
  following: number;
  accountsEngaged: number;
  videoViews: number;
  accountsReached: number;
  growthRate: number;
  femaleRatio: number;
  maleRatio: number;
  topCityPercentage: number;
  topCountryPercentage: number;
  topCity: string;
  topCountry: string;
  topAgeRange: string;
  accountVisibility: string;
  commission: string;
};
type InsightsFormType = {
  type: string;
  insights: InsightType;
};
const countries = ["England", "Northern Ireland", "Scotland", "Wales"];
const cities: any = {
  England: [
    "Bath",
    "Birmingham",
    "Bradford",
    "Brighton and Hove",
    "Bristol",
    "Cambridge",
    "Canterbury",
    "Carlisle",
    "Chelmsford",
    "Chester",
    "Chichester",
    "Colchester",
    "Coventry",
    "Derby",
    "Doncaster",
    "Durham",
    "Ely",
    "Exeter",
    "Gloucester",
    "Hereford",
    "Kingston upon Hull",
    "Lancaster",
    "Leeds",
    "Leicester",
    "Lichfield",
    "Lincoln",
    "Liverpool",
    "London",
    "Manchester",
    "Milton Keynes",
    "Newcastle upon Tyne",
    "Norwich",
    "Nottingham",
    "Oxford",
    "Peterborough",
    "Plymouth",
    "Portsmouth",
    "Preston",
    "Ripon",
    "Salford",
    "Salisbury",
    "Sheffield",
    "Southampton",
    "Southend-on-Sea",
    "St Albans",
    "Stoke-on-Trent",
    "Sunderland",
    "Truro",
    "Wakefield",
    "Wells",
    "Westminster",
    "Winchester",
    "Wolverhampton",
    "Worcester",
    "York",
  ],
  "Northern Ireland": [
    "Armagh",
    "Bangor",
    "Belfast",
    "Lisburn",
    "Londonderry",
    "Newry",
  ],
  Scotland: [
    "Aberdeen",
    "Dundee",
    "Dunfermline",
    "Edinburgh",
    "Glasgow",
    "Inverness",
    "Perth",
    "Stirling",
  ],
  Wales: [
    "Bangor",
    "Cardiff",
    "Newport",
    "St Asaph (Llanelwy)",
    "St Davids",
    "Swansea",
    "Wrexham",
  ],
};
const age_range: string[] = [
  "18 - 24",
  "25 - 34",
  "35 - 44",
  "45 - 54",
  "55 - 64",
  "65+",
];
const account_visibility: string[] = ["Public", "Private"];

const InsightsForm = ({ type, insights }: InsightsFormType) => {
  const [updateTiktok] = useMutation(UPDATE_TIKTOK_STATS);
  const [updateInstagram] = useMutation(UPDATE_INSTAGRAM_STATS);

  const handleUpdate = (type: string) => {
    switch (type) {
      case "TikTok":
        updateTiktok();
        break;
      case "Instagram":
        updateInstagram();
        break;
      default:
        break;
    }
  };
  return (
    <Grid
      item
      xs={6}
      p={2}
      m={2}
      component={Paper}
      sx={{ maxWidth: "600px !important" }}
    >
      <Typography variant="h6" gutterBottom>
        {type} Insights
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="link"
            name="link"
            label={`${type} Link`}
            fullWidth
            autoComplete={`${type}-handle`}
            variant="standard"
            defaultValue={insights?.link || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="following"
            name="following"
            label="Following"
            fullWidth
            autoComplete="following"
            variant="standard"
            defaultValue={insights?.following || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="accounts-engaged"
            name="accounts-engaged"
            label="Accounts Engaged"
            fullWidth
            autoComplete="accounts-engaged"
            variant="standard"
            defaultValue={insights && insights?.accountsEngaged}
          />
        </Grid>
        <Grid item xs={6}>
          {type === "TikTok" && (
            <TextField
              id="video-views"
              name="video-views"
              label="Video Views"
              fullWidth
              autoComplete="video-views"
              variant="standard"
              defaultValue={insights && insights?.videoViews}
            />
          )}
          {type === "Instagram" && (
            <TextField
              id="accounts-reached"
              name="accounts-reached"
              label="Accounts Reached"
              fullWidth
              autoComplete="accounts-reached"
              variant="standard"
              defaultValue={insights && insights?.accountsReached}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="growth-rate"
            name="growth-rate"
            label="Growth Rate"
            fullWidth
            autoComplete="growth-rate"
            variant="standard"
            defaultValue={insights && insights?.growthRate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="female-ratio"
            name="female-ratio"
            label="Female Ratio (%)"
            fullWidth
            variant="standard"
            defaultValue={insights && insights?.femaleRatio}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="male-ratio"
            name="male-ratio"
            label="Male Ratio (%)"
            fullWidth
            variant="standard"
            defaultValue={insights && insights?.maleRatio}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            id="country-selector"
            defaultValue={insights?.topCountry}
            options={countries}
            // onChange={(event, value) => saveSelectedIds(value)}
            getOptionLabel={(list: string) => list}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Top Country"
                placeholder={insights?.topCountry}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="top-country-percentage"
            name="top-country-percentage"
            label="Top Country Percentage"
            fullWidth
            autoComplete="top country percentage"
            variant="standard"
            defaultValue={insights?.topCountryPercentage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            id="city-selector"
            options={cities}
            defaultValue={insights?.topCity}
            // onChange={(event, value) => saveSelectedIds(value)}
            getOptionLabel={(list: string) => list}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Top City"
                placeholder={insights?.topCity}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="top-city-percentage"
            name="top-city-percentage"
            label="Top City Percentage"
            fullWidth
            autoComplete="top city percentage"
            variant="standard"
            defaultValue={insights?.topCityPercentage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            id="age-range-selector"
            options={age_range}
            defaultValue={insights?.topAgeRange}
            // onChange={(event, value) => saveSelectedIds(value)}
            getOptionLabel={(list: string) => list}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Top Age Range"
                placeholder={insights?.topAgeRange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            id="account-visibility-selector"
            options={account_visibility}
            defaultValue={insights?.accountVisibility}
            // onChange={(event, value) => saveSelectedIds(value)}
            getOptionLabel={(list: string) => list}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Profile Visibility"
                placeholder={insights?.accountVisibility}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {type === "TikTok" && (
            <TextField
              id="commission"
              name="commission"
              label="Commission"
              fullWidth
              autoComplete="commission"
              variant="standard"
              defaultValue={insights && insights?.commission}
            />
          )}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={() => handleUpdate("bro")}
        sx={{ margin: 2 }}
      >
        Update
      </Button>
    </Grid>
  );
};
