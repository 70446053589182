import { gql } from "@apollo/client";

const CHANGE_BRAND = gql`
  mutation adminUpdateBrand(
    $brandId: ID!
    $agencyCommission: Int!
  ) {
    adminUpdateBrand(
      brandId: $brandId
      agencyCommission: $agencyCommission
    )
  }
`;

export default CHANGE_BRAND;
