import { gql } from "@apollo/client";

const GET_INFLUENCERS = gql`
  query adminGetInfluencers {
    adminGetInfluencers {
      id
      name
      image
      email
      country
      city
      deletedAt
      accountStatus
      lastSignInAt
      currentSignInAt
      createdAt
      hasGallery
      hasInsights
      categories {
        id
        name
      }
    }
  }
`;

export default GET_INFLUENCERS;
