import { gql } from "@apollo/client";

const GET_SINGLE_INFLUENCER = gql`
  query adminGetSingleInfluencer($influencerId: ID!) {
    adminGetSingleInfluencer(influencerId: $influencerId) {
      id
      categories {
        id
        name
      }
      city
      country
      createdAt
      email
      facebook
      image
      instagram
      name
      tiktok
      website
      youtube
    }
  }
`;

export default GET_SINGLE_INFLUENCER;
