import { gql } from "@apollo/client";

const FETCH_NOTIFICATIONS = gql`
  query adminGetNotifications($userId: ID!) {
    adminGetNotifications(userId: $userId) {
      id
      createdAt
      text
      url
    }
  }
`;

export default FETCH_NOTIFICATIONS;
