import { gql } from "@apollo/client";

const GET_BRAND_WISHLIST_COUNT = gql`
  query getBrandWishlistCount($brandIds: [ID!], $brandNames: [String!]) {
    getBrandWishlistCount(brandIds: $brandIds, brandNames: $brandNames) {
      brandId
      brandName
      count
    }
  }
`;

export default GET_BRAND_WISHLIST_COUNT;
