import { useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import GET_RESPONDER_LIST from "../../graphql/queries/getSurveyAsnwerDetails";

type InfluencerListType = {
  open: boolean;
  setOpen: any;
  surveyId: number | string;
  answer: string;
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const InfluencerListModal = ({
  open,
  setOpen,
  surveyId,
  answer,
}: InfluencerListType) => {
  const nav = useNavigate();
  const { data } = useQuery(GET_RESPONDER_LIST, {
    variables: {
      surveyId: surveyId,
      answer: answer,
    },
  });
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          align="center"
        >
          Response
        </Typography>
        <Typography variant="h5" align="center">
          "<b>{answer}</b>"
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 3, maxHeight: 800 }}>
          <Table aria-label="survey listing table">
            <TableHead>
              <TableRow>
                <TableCell width="20">#</TableCell>
                <TableCell width="30"> </TableCell>
                <TableCell align="left">
                  <b>Influencer Name</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.adminGetSurveyAnswerDetails.map(
                (answer: any, index: number) => (
                  <TableRow
                    key={`${answer.id}-${index}`}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "" : "whitesmoke",
                      cursor: "pointer",
                    }}
                    onClick={() => nav(`/influencer/${answer.user.id}`)}
                  >
                    <TableCell component="th" scope="row">
                      {index}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Avatar
                        alt={answer.user.name}
                        src={answer.user.image}
                        sx={{ width: 24, height: 24 }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {answer.user.name}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default InfluencerListModal;
