import React, { ReactElement, FC, useState, useMemo } from "react";
import {
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  styled,
  TableCell,
  tableCellClasses,
  TableBody,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import GET_BRANDS from "../graphql/queries/getBrands";
import APPROVE_USER from "../graphql/mutations/approveUser";
import REJECT_USER from "../graphql/mutations/rejectUser";
import DeleteRestoreButton from "../components/SharedComponents/deleteRestoreButton";
import { Select, MenuItem, Box } from "@mui/material";
import DetailsModal from "../components/brandsComponent/DetailsModal";
type CategoryType = {
  id: string;
  name: string;
};
type InfluencerType = {
  id: string;
  brandName: string;
  image: string;
  email: string;
  country: string;
  city: string;
  accountStatus: string;
  categories: CategoryType[];
  lastSignInAt: string;
  currentSignInAt: string;
  deletedAt: string;
  createdAt: string;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const convertDate = (date: string) => {
  const formatedDate = new Date(date);
  return formatedDate.toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

const BrandListing: FC<any> = (): ReactElement => {
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");
  const [data, setData] = useState<InfluencerType[]>([]);  // Initialize as empty array
  const [open, setOpen] = useState(false)
  const [brand, setBrand] = useState();

  const [approve] = useMutation(APPROVE_USER, {
    refetchQueries: ["adminGetSingleBrandMinified", "adminGetBrands"],
  });
  const [reject] = useMutation(REJECT_USER, {
    refetchQueries: ["adminGetSingleBrandMinified", "adminGetBrands"],
  });

  useQuery(GET_BRANDS, {
    onCompleted: (res) => {
      setData(res.adminGetBrands);
    },
  });

  const sortListing = (listing: InfluencerType[]) => {
    return [...listing].sort((a, b) => {
      if (sortOrder === "newest") {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      }
    });
  };

  const sortedData = useMemo(() => sortListing(data), [data, sortOrder]);

  const handleDetails = (brand: any) => {
    setOpen(true)
    setBrand(brand)
  };

  return (
      <Grid
          item
          xs={12}
          sx={{
            width: "100vw",
            height: "calc(100vh)",
            backgroundColor: "whitesmoke",
            padding: 5,
          }}
      >
        <Typography variant="h4" align="center" sx={{ marginBottom: 5 }}>
          Brand Listing
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Select
              value={sortOrder}
              sx={{ fontSize: '15px', marginBottom: '10px' }}
              onChange={(event) => setSortOrder(event.target.value as "newest" | "oldest")}
          >
            <MenuItem value={"newest"} sx={{ fontSize: '15px' }}>Newest</MenuItem>
            <MenuItem value={"oldest"} sx={{ fontSize: '15px' }}>Oldest</MenuItem>
          </Select>
        </Box>
        {data && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1200 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ width: 50 }}>#</StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 300 }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Country</StyledTableCell>
                    <StyledTableCell align="left">City</StyledTableCell>
                    <StyledTableCell align="left">Niches</StyledTableCell>
                    <StyledTableCell align="left">Last Login</StyledTableCell>
                    <StyledTableCell align="left">Created At</StyledTableCell>
                    <StyledTableCell align="center">Approve</StyledTableCell>
                    <StyledTableCell align="center">Reject</StyledTableCell>
                    <StyledTableCell align="center">Delete/Restore</StyledTableCell>
                    <StyledTableCell align="center">Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row: InfluencerType, index: number) => (
                      <StyledTableRow key={row.id}>  {/* Use unique key */}
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.brandName}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.email}</StyledTableCell>
                        <StyledTableCell align="left">{row.country}</StyledTableCell>
                        <StyledTableCell align="left">{row.city}</StyledTableCell>
                        <StyledTableCell
                            align="left"
                            sx={{ width: 200, wordBreak: "break-word" }}
                        >
                          {row.categories.map((category: CategoryType) => `${category.name}, `)}
                        </StyledTableCell>
                        <StyledTableCell
                            align="left"
                            sx={{ width: 400 }}
                        >
                          {row.currentSignInAt ? convertDate(row.currentSignInAt) : ""}
                        </StyledTableCell>
                        <StyledTableCell
                            align="left"
                            sx={{ width: 400 }}
                        >
                          {row.createdAt ? convertDate(row.createdAt) : ""}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                              size="small"
                              variant="contained"
                              color="success"
                              disabled={
                                  row.accountStatus === "Approved" ||
                                  row.accountStatus === "Rejected"
                              }
                              onClick={() =>
                                  approve({
                                    variables: {
                                      userId: row.id,
                                    },
                                  })
                              }
                          >
                            Approve
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                              size="small"
                              variant="contained"
                              color="error"
                              disabled={row.accountStatus !== "Pending"}
                              onClick={() =>
                                  reject({
                                    variables: {
                                      userId: row.id,
                                    },
                                  })
                              }
                          >
                            Reject
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <DeleteRestoreButton user={row} refetchQueries={["adminGetSingleBrandMinified", "adminGetBrands"]}/>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                              size="small"
                              variant="contained"
                              disabled={row.accountStatus === "Pending" || !!row.deletedAt}
                              onClick={() => handleDetails(row)}
                          >

                            Details
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            
            <DetailsModal open={open} setOpen={setOpen} brand={brand}/>
            </TableContainer>
        )}
      </Grid>
  );
};

export default BrandListing;
