import { gql } from "@apollo/client";

const GET_SURVEY_LIST = gql`
  query adminGetSurveys {
    adminGetSurveys {
      id
      question
      coverImage
      createdAt
      answers
      isEnabled
      brandName
      surveyAnswers {
        answer
      }
    }
  }
`;

export default GET_SURVEY_LIST;
