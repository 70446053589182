import { useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Modal,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import React, { useState, useEffect }from "react";
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CHANGE_CAMPAIGN from "../../graphql/mutations/updateCampaign";
import { useMutation } from "@apollo/client";

import { ThemeProvider, createTheme } from '@mui/material/styles';


type editCampaignType = {
  openEdit: boolean;
  setOpenEdit: any;
  campaign: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditCampaign = ({
  openEdit,
  setOpenEdit,
  campaign,
}: editCampaignType) => {

  const [startDate, setStartDate] = useState(campaign?.startDate ? moment.utc(campaign.startDate) : moment());
  const [endDate, setEndDate] = useState(campaign?.endDate ? moment.utc(campaign.endDate) : moment());
  
  const [updateCampaign] = useMutation(CHANGE_CAMPAIGN, {
    refetchQueries: ["adminGetCampaigns"],
  });

  useEffect(() => {
    if(campaign?.startDate){
      setStartDate(moment.utc(campaign.startDate))
    }
    if(campaign?.endDate){
      setEndDate(moment.utc(campaign.endDate))
    }
    }, [campaign]);


  const update = () => {
    updateCampaign({
      variables: {
        campaignId: campaign.id,
        startDate: startDate,
        endDate: endDate,
      },
    });
    setOpenEdit(false)
  }
  return (
    <Modal
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          align="center"
        >
          Edit Campaign 
        </Typography>


          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Start Date"
                value={startDate}
                format="DD/MM/YYYY"
                onChange={(newValue) => setStartDate(newValue ? newValue : moment())}
                slotProps={{ textField: { variant: 'standard', fullWidth: true, } }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{marginTop: 2}}>
              <DatePicker
                label="End Date"
                value={endDate}
                format="DD/MM/YYYY"
                onChange={(newValue) => setEndDate(newValue ? newValue : moment())}
                slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
              />
            </Grid>
          </LocalizationProvider>

        <Button
          variant="contained"
          onClick={() => update()}
          sx={{ height: 45, marginTop: 2 }}
        >
          Update
        </Button>


       
      </Box>
    </Modal>
  );
};

export default EditCampaign;
