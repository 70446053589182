import React from "react";
import { Grid, List, ListItemText, Typography } from "@mui/material";
import theme from "../../globalTheme";
import moment from 'moment';

const CampaignDetailBanner = (props: any) => {
  const { name, description, type, media, category, influencerCount, startDate, endDate } = props;

const formatDate = (date: any) => {
  return moment.utc(date).format('DD/MM/YYYY')
};

  return (
    <Grid
      item
      xs={12}
      container
      sx={{
        minHeight: 250,
        borderRadius: 5,
        padding: 2,
        backgroundColor: theme.palette.primary.light,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
      justifyContent="center"
    >
      <Typography variant="h4" align="center" mb={2}>
        {name}
      </Typography>
      <Typography variant="body1" align="center" mb={3}>
        {description}
      </Typography>
      <List
        dense
        sx={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <ListItemText
          primary={"Campaign Type"}
          secondary={type || "-"}
          primaryTypographyProps={{
            textAlign: "center",
            fontWeight: 700,
          }}
          secondaryTypographyProps={{
            textAlign: "center",
          }}
          sx={{ width: 180 }}
        />
        <ListItemText
          primary={"Campaign Media"}
          secondary={media || "-"}
          primaryTypographyProps={{
            textAlign: "center",
            fontWeight: 700,
          }}
          secondaryTypographyProps={{
            textAlign: "center",
          }}
          sx={{ width: 180 }}
        />
        <ListItemText
          primary={"Campaign Category"}
          secondary={category || "-"}
          primaryTypographyProps={{
            textAlign: "center",
            fontWeight: 700,
          }}
          secondaryTypographyProps={{
            textAlign: "center",
          }}
          sx={{ width: 180 }}
        />
        <ListItemText
          primary={"Influencer Count"}
          secondary={influencerCount || "-"}
          primaryTypographyProps={{
            textAlign: "center",
            fontWeight: 700,
          }}
          secondaryTypographyProps={{
            textAlign: "center",
          }}
          sx={{ width: 180 }}
        />
        <ListItemText
          primary={"Start Date"}
          secondary={formatDate(startDate) || "-"}
          primaryTypographyProps={{
            textAlign: "center",
            fontWeight: 700,
          }}
          secondaryTypographyProps={{
            textAlign: "center",
          }}
          sx={{ width: 180 }}
        />

        <ListItemText
          primary={"End Date"}
          secondary={formatDate(endDate) || "-"}
          primaryTypographyProps={{
            textAlign: "center",
            fontWeight: 700,
          }}
          secondaryTypographyProps={{
            textAlign: "center",
          }}
          sx={{ width: 180 }}
        />

      </List>
    </Grid>
  );
};

export default CampaignDetailBanner;
