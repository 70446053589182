import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const CategoryPopulation = ({ rows, title }: any) => {
  return (
    <Table sx={{ maxWidth: 500 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Category Name</b>
          </TableCell>
          <TableCell align="center">
            <b>No. of {title}s</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: any) => (
          <TableRow
            key={row.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="center">{row.count}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CategoryPopulation;
