import { gql } from "@apollo/client";

const GET_CATEGORIES = gql`
  query adminGetCategories {
    adminGetCategories {
      id
      name
      status
    }
  }
`;

export default GET_CATEGORIES;
