import React, { ReactElement, FC, useState } from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  Paper,
  Avatar,
  TextField,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import LOGIN from "../../graphql/mutations/login";
import { useNavigate } from "react-router-dom";
import ADMIN_AUTH from "../../graphql/queries/adminAuth";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" mt={5}>
      {"Copyright © "}
      <Link color="inherit" href="https://brands.authentic-influencers.com/">
        Authentic Influencers
      </Link>
      &emsp;{new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const collageData = [
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/1.jpeg",
    title: "influencer-img-1",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/10.png",
    title: "influencer-img-10",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/2.jpg",
    title: "influencer-img-2",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/3.png",
    title: "influencer-img-3",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/4.png",
    title: "influencer-img-4",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/5.png",
    title: "influencer-img-5",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/6.png",
    title: "influencer-img-6",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/7.png",
    title: "influencer-img-7",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/8.jpg",
    title: "influencer-img-8",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/9.jpg",
    title: "influencer-img-9",
  },
  {
    img: "https://authentic-pps.s3.eu-west-2.amazonaws.com/landing-page-images/10.png",
    title: "influencer-img-10",
  },
];

const Login: FC<any> = ({ isUser }): ReactElement => {
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin] = useLazyQuery(ADMIN_AUTH, {
    fetchPolicy: "network-only",
    variables: {
      email: email,
    },
    onCompleted: (res) => {
      if (res.adminAuth) {
        loginUser({
          variables: {
            email: email,
            password: password,
          },
        });
      } else {
        window.alert("You do not have access to this area");
      }
    },
  });
  const [loginUser] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res.userLogin.authenticatable.userType === "Admin") {
        localStorage.setItem("currentUser", res.userLogin.authenticatable.id);
        localStorage.setItem(
          "accessToken",
          res.userLogin.credentials.accessToken
        );
        localStorage.setItem("client", res.userLogin.credentials.client);
        localStorage.setItem("uid", res.userLogin.credentials.uid);
        isUser(true);
        nav("/");
      } else {
      }
    },
    onError: (res) => {
      window.alert("Invalid Credentials");
    },
  });

  function handleLogin() {
    isAdmin();
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} sm={5} md={3} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              m: 1,
              backgroundColor: "transparent",
              width: 200,
              height: 60,
            }}
            variant="square"
          >
            <img src="www.logo.com" alt="authentic-influencers" width="200" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Admin Dashboard
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
              disabled={email === "" || password === ""}
            >
              Sign In
            </Button>
            <Copyright />
          </Box>
        </Box>
      </Grid>
      <Grid item xs sx={{ maxHeight: "100vh", overflowY: "scroll" }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {collageData.map((item, index) => (
            <ImageListItem key={"image-" + index}>
              <img
                src={`${item.img}?w=220&fit=crop&auto=format`}
                srcSet={`${item.img}?w=220&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </Grid>
  );
};

export default Login;
