import { gql } from "@apollo/client";

const GET_BRANDS = gql`
  query adminGetBrands {
    adminGetBrands {
      id
      brandName
      image
      email
      country
      city
      accountStatus
      lastSignInAt
      currentSignInAt
      deletedAt
      createdAt
      agencyCommission
      categories {
        id
        name
      }
    }
  }
`;

export default GET_BRANDS;
