import { gql } from "@apollo/client";

const GET_ADMIN_INFLUENCERS_FOR_CAMPAIGN = gql`
    query getAdminInfluencersForCampaign($campaignId: ID!) {
        getAdminInfluencersForCampaign(campaignId: $campaignId) {
            id
            name
            contentMedia
            contentStatus
            submitted
            rejected
            pending
            accepted
            influencerId
            taskDescription
            remainingContent
            remainingInfluencers
            campaignResult
        }
    }
`;

export default GET_ADMIN_INFLUENCERS_FOR_CAMPAIGN;