import { gql } from "@apollo/client";

const EDIT_CATEGORY = gql`
  mutation adminEditCategory(
    $categoryId: ID!
    $change: String!
    $value: String
  ) {
    adminEditCategory(categoryId: $categoryId, change: $change, value: $value)
  }
`;

export default EDIT_CATEGORY;
