import { gql } from "@apollo/client";

const GET_CAMPAIGNS_FOR_CATEGORY = gql`
  query getCampaignsForCategory($categoryId: ID!) {
    getCampaignsForCategory(categoryId: $categoryId) {
      id
      name
      description
    }
  }
`;

export default GET_CAMPAIGNS_FOR_CATEGORY;
