import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Box, Typography, Container, TextField,CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import { useQuery, useMutation } from '@apollo/client';
import GET_CAMPAIGN_DETAILS from '../../graphql/queries/getCampaignDetails';
import GET_BRAND from '../../graphql/queries/getBrandInfo';
import UPDATE_USER_INFO from '../../graphql/mutations/updateUserInfo';
import CampaignDetailBanner from './campaignDetailBanner';
import MiniInfluencerCard from './miniInfluencerCard';
import { AnyARecord } from 'dns';
interface CampaignDetails {
  id: string;
  name: string;
  description: string;
  campaignType: string;
  media: string;
  category: { name: string };
  campaignContracts: ContractDetails[];
  startDate: string;
  endDate: string;
  signature?: string;
}

interface ContractDetails {
  id: string;
  influencerName: string;
}
const CampaignDetail: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); 
  const { data: campaignData } = useQuery<{ getCampaignDetails: CampaignDetails }>(GET_CAMPAIGN_DETAILS, {
    fetchPolicy: 'network-only',

    variables: {
      campaignId: id ?? '',
    },
  });
  const adminId = localStorage.getItem('currentUser');
  if (id) {
    localStorage.setItem('campaignId', id);
  }
  const { data: brandData } = useQuery(GET_BRAND, {
    variables: { userId: adminId },
    skip: !adminId,
  });
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [signatureData, setSignatureData] = useState('');
  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);
  const sigPad = useRef<SignaturePad | null>(null);
  const handleSaveSignature = async () => {
    if (sigPad.current) {
      const signature = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
      setSignatureData(signature);
      try {
        if (!adminId) {
          console.error('Admin ID is undefined or null.');
          return;
        }
        await updateUserInfo({
          variables: {
            userId: adminId,
            signature: signature,
          },
        });
        setShowSignaturePad(false);
      } catch (error) {
        console.error('Error saving signature:', error);
      }
    }
  };

  return (
    <>
  
  <Grid
      item
            xs={12}
      sx={{
        width: "100vw",
        height: "calc(100vh - 64px)",
        backgroundColor: "whitesmoke",
        padding: 4,
        overflowY: "scroll",
      }}
      container
      justifyContent="start"
    >       
     <CampaignDetailBanner
          name={campaignData?.getCampaignDetails?.name}
          description={campaignData?.getCampaignDetails?.description}
          type={campaignData?.getCampaignDetails?.campaignType}
          media={campaignData?.getCampaignDetails?.media}
          category={campaignData?.getCampaignDetails.category?.name}
          influencerCount={campaignData?.getCampaignDetails?.campaignContracts?.length}
          startDate={campaignData?.getCampaignDetails?.startDate}
          endDate={campaignData?.getCampaignDetails?.endDate}
        />

        {/* Display Mini Influencer Cards */}
        <Grid item xs={12} container justifyContent="center" mt={2}>
          {campaignData?.getCampaignDetails?.campaignContracts?.map((contract: any) => (
            <MiniInfluencerCard
              key={contract.id}
              contractDetails={contract}
              campaign={campaignData?.getCampaignDetails}
            />
          ))}
        </Grid>
     {/* Signature Pad */}
     {showSignaturePad && (
          <Grid item xs={12} container justifyContent="center" mt={2}>
            <Box>
              <SignaturePad
                ref={sigPad}
            canvasProps={{ width: 300, height: 150, className: 'sigCanvas' ,style: { backgroundColor: '#f0f0f0' }}}
              />
              <Button variant="contained" color="primary" onClick={handleSaveSignature}>
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => sigPad.current?.clear()}
                sx={{ marginLeft: 2 }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        )}

        {/* Button to Edit/Add Signature */}
        <Grid item xs={12} container justifyContent="center" mt={2}>
          {!showSignaturePad && (
            <Box>
              {brandData?.getBrand?.adminSignature ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowSignaturePad(true)}
                >
                  Edit Signature
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowSignaturePad(true)}
                >
                  Add Signature
                </Button>
              )}
            </Box>
          )}
        </Grid>
     
      </Grid>
    </>
  );
};

export default CampaignDetail;
