import createTheme from "@mui/material/styles/createTheme";

const theme = createTheme({
  palette: {
    primary: {
      light: "#FFD8E8",
      main: "#FFA3C7",
      dark: "#EF2E83",
      contrastText: "#000",
    },
    success: {
      main: "#9DF9B9",
      light: "#5EFC8D",
      dark: "#057928",
      contrastText: "#000",
    },
    warning: {
      main: "#F1D68D",
      light: "#E7BB41",
      dark: "#B99634",
      contrastText: "#000",
    },
  },
});

export default theme;
