import { gql } from "@apollo/client";

const GET_CAMPAIGNS = gql`
  query adminGetCampaigns {
    adminGetCampaigns {
      id
      name
      startDate
      endDate
      createdAt
      user {
        name
      }
      campaignType
      category {
        name
      }
      followerPreference
      media
      status
    }
  }
`;

export default GET_CAMPAIGNS;
