import { gql } from "@apollo/client";

const GET_INVOICED_CAMPAIGNS = gql`
query getInvoicedCampaigns($campaignId: ID!) {
  getInvoicedCampaigns(campaignId: $campaignId) {
      id
      name
      coverPic
      media
      campaignType
      status
      contract
      cost
      costType
      description
      includesStories
      includesReels
      includesVideos
      includesPictures
      requiresUsageRights
      usageRightsPeriod
      started
      brandName
      influencersCount
      campaignContracts {
        id
        userId
        influencerQuote
        brandQuote
        contractType
        description
        finalFee
        platformFee
        influencerName
        influencerImage
      }
    }
  }
`;

export default GET_INVOICED_CAMPAIGNS;
