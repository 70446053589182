import { gql } from "@apollo/client";

const GET_CAMPAIGN_DETAILS = gql`
  query getCampaignDetails($campaignId: ID!) {
    getCampaignDetails(campaignId: $campaignId) {
      id
      name
      description
      campaignType
      media
      startDate
      endDate
      contract
      commissionModel
      category {
        id
        name
      }
      campaignContracts {
        id
        description
        contractType
        contractStatus
        influencerQuote
        brandQuote
        finalFee
        commissionModel
        influencer {
          id
          name
          image
        }
      }
    }
  }
`;

export default GET_CAMPAIGN_DETAILS;
