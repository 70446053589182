import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import React from "react";
import GET_INFLUENCER_GALLERY from "../../graphql/queries/getInfluencerGallery";
import GalleryCard from "./GalleryCard";

type InfluencerGalleryType = {
  id: string;
};
type GalleryItemType = {
  id: number;
  item: string;
};
const InfluencerGallery = ({ id }: InfluencerGalleryType) => {
  const { data } = useQuery(GET_INFLUENCER_GALLERY, {
    variables: { userId: id },
  });
  if (!data) return <></>;
  return (
    <Grid
      item
      xs={12}
      sx={{ minWidth: "70vw" }}
      container
      justifyContent="space-around"
    >
      {data.getInfluencerGallery.map((item: GalleryItemType, index: number) => (
        <GalleryCard
          item={item.item}
          index={item.id}
          key={`gallery-item-${index}-${item.id}`}
        />
      ))}
    </Grid>
  );
};

export default InfluencerGallery;
