import { gql } from "@apollo/client";

const UPDATE_INSTAGRAM_STATS = gql`
  mutation submitInstagramStat(
    $userId: ID!
    $link: String!
    $following: Int!
    $topCountry: String
    $topCountryPercentage: Int
    $topCity: String
    $topCityPercentage: Int
    $femaleRatio: Int
    $maleRatio: Int
    $growthRate: Int
    $accountsEngaged: Int
    $accountVisibility: Int
    $accountsReached: Int
    $topAgeRange: String
  ) {
    submitInstagramStat(
      userId: $userId
      link: $link
      following: $following
      topCountry: $topCountry
      topCountryPercentage: $topCountryPercentage
      topCity: $topCity
      topCityPercentage: $topCityPercentage
      femaleRatio: $femaleRatio
      maleRatio: $maleRatio
      growthRate: $growthRate
      accountsEngaged: $accountsEngaged
      accountVisibility: $accountVisibility
      accountsReached: $accountsReached
      topAgeRange: $topAgeRange
    )
  }
`;

export default UPDATE_INSTAGRAM_STATS;
