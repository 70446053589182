import { styled } from "@mui/material/styles";
import { Card, CardContent, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@apollo/client";
import DELETE_ITEM from "../../graphql/mutations/deleteInfluencerGalleryItem";

const StyledCardMedia = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
  height: 650,
});

const StyledImage = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
  position: "absolute",
});
const StyledVideo = styled("video")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

type GalleryCardType = {
  item: string;
  index: number;
};

export default function GalleryCard({ item, index }: GalleryCardType) {
  function is_it_an_image(url: string) {
    //@ts-ignore
    const fileType = url.split(/[#?]/)[0].split(".").pop().trim();

    return fileType === "jpg" || fileType === "jpeg" || fileType === "png";
  }

  const [deleteItem] = useMutation(DELETE_ITEM, {
    variables: { itemId: index },
    refetchQueries: ["getInfluencerGallery"],
  });
  return (
    <Card sx={{ position: "relative", minWidth: 400, margin: 3 }}>
      <StyledCardMedia>
        {is_it_an_image(item) ? (
          <StyledImage alt={`gallery-post-${index}`} src={item} />
        ) : (
          <StyledVideo src={item} controls loop />
        )}
      </StyledCardMedia>
      <CardContent
        sx={{
          padding: "5px 0px 5px 5px",
          "&:last-child": { paddingBottom: "5px" },
        }}
      >
        <IconButton
          aria-label="delete"
          size="large"
          sx={{ padding: 0 }}
          onClick={() => deleteItem()}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </CardContent>
    </Card>
  );
}
