import { gql } from "@apollo/client";

const GET_INFLUENCER_GALLERY = gql`
  query getInfluencerGallery($userId: ID!) {
    getInfluencerGallery(userId: $userId) {
      id
      item
    }
  }
`;

export default GET_INFLUENCER_GALLERY;
