import { gql } from "@apollo/client";

const GET_PRE_INVOICE = gql`
  query getPreInvoice($userId: ID!, $campaignId: ID!) {
    getPreInvoice(userId: $userId, campaignId: $campaignId) {
      id
      finalFee
      influencerQuote
      platformFee
      campaign
      {
        id
        name
        vat
      }
      agencyCommission
    }
  }
`;

export default GET_PRE_INVOICE;
